import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const FuneralPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Funeral"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <article className="post-content page-template  no-image">
        <div className="post-content-body ">
          <h2>Funeral was held in Melbourne, Australia on 2020 July 1st</h2>
          <figure className="kg-card kg-image-card">
            <iframe
              width="720"
              height="480"
              src="https://www.youtube.com/embed/gET5S1AQMZg"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </figure>
        </div>
      </article>
      <article className="post-content page-template  no-image">
        <div className="post-content-body ">
          <h2>Burial was held in Melbourne, Australia on 2020 July 1st</h2>
          <figure className="kg-card kg-image-card">
            <iframe
              width="720"
              height="480"
              src="https://www.youtube.com/embed/dQ6fXPJ4epc"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </figure>
        </div>
      </article>

      <article className="post-content page-template no-image">
        <div className="post-content-body ">
          <h2>
            Viewing and rosary was held in Melbourne, Australia on 2020 June
            29th
          </h2>
          <figure className="kg-card kg-image-card">
            <a
              href="https://www.oneroomstreaming.com/view/authorise.php?k=1593351068261561"
              target="viewing"
            >
              <Img
                fluid={data.viewing.childImageSharp.fluid}
                className="kg-image"
              />
            </a>
          </figure>
          <figcaption>Viewing services provided by OneRoom.</figcaption>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    viewing: file(relativePath: { eq: "viewing.png" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <FuneralPage location={props.location} data={data} {...props} />
    )}
  />
)
